/*----- 01 CUSTOM PROPERTIES-----*/
:root {
    --white-f: #ffffff;
    --black-0: #000000;
    --black: #050505;
    --black-2: #1d1d1d;
    --orange: #F2584C;
    --green: #0F8C50;

    // FONTS
    --inter: "Inter", sans-serif;
    --mulish: 'Mulish', serif;
    --newsreader: 'Newsreader', serif;
    --sahitya: 'Sahitya', serif;
    --philosopher: 'Philosopher', sans-serif;
    --oldenburg: 'Oldenburg', serif;
}