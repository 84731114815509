/* ----- 38. BLOG DETAILS ----- */
.rv-blog-details {
    @include xl {
        padding: 80px 0;
    }

    @include md {
        padding: 60px 0;
    }

    &__row {
        --bs-gutter-x: 60px;
        --bs-gutter-y: 30px;

        @include xl {
            --bs-gutter-x: 40px;
        }

        @include lg {
            --bs-gutter-x: 30px;
        }

        @include md {
            --bs-gutter-y: 30px;
        }
    }

    &__img {
        position: relative;

        img {
            width: 100%;
            max-height: 400px;
        }
    }

    &__infos {
        display: flex;
        align-items: center;
        margin: 35px 0 27px;
        gap: 20px;

        @include xl {
            margin: 20px 0 25px;
        }

        @include xxs {
            flex-wrap: wrap;
            row-gap: 12px;
            margin: 20px 0 15px;
        }
    }

    &__info {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 0;
        color: rgb(5 5 5 / 75%);
        font-family: var(--mulish);

        i {
            font-size: 15px;
            vertical-align: top;
            padding-right: 5px;

            @include lg {
                font-size: 14px;
            }
        }

    }

    &__title {
        color: var(--black);
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 11px;

        @include lg {
            font-size: 24px;
        }

        @include sm {
            font-size: 22px;
        }

        @include xxs {
            font-size: 20px;
        }
    }

    &-descr {
        &__txt {
            color: rgb(5 5 5 / 70%);
            font-family: var(--mulish);
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 20px;

            @include xxs {
                font-size: 16px;
            }

            &.blockquote {
                background-color: #F5F6FA;
                padding: 98px 110px 45px;
                margin: 54px 0 60px;
                position: relative;
                text-align: center;

                @include lg {
                    padding: 88px 90px 35px;
                    margin: 34px 0 40px;
                }

                @include sm {
                    padding: 88px 50px 25px;
                    margin: 24px 0 30px;
                }

                @include xs {
                    padding: 88px 20px 25px;
                }

                &::before {
                    content: open-quote;
                    font-size: 106px;
                    font-weight: 700;
                    position: absolute;
                    font-family: var(--philosopher);
                    top: 45px;
                    left: 50%;
                    translate: -50% 0;
                    color: rgb(5 5 5 / 20%);
                    line-height: 0.65;
                }

                p {
                    color: rgb(5 5 5 / 60%);
                    font-size: 18px;
                    line-height: 155.556%;
                    margin-bottom: 23px;

                    @include xxs {
                        font-size: 16px;
                    }
                }

                .blockquote__author-name {
                    color: #050505;
                    font-weight: 700;
                    margin-bottom: 0;
                    font-size: 18px;
                }
            }
        }

        > :last-child {
            margin: 0;
        }
    }

    &__inner-img {
        margin-bottom: 30px;

        img {
            height: 390px;
            width: 100%;
        }
    }

    &-bottom {
        &-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 80px;

            @include xl {
                padding-top: 50px;
            }

            @include lg {
                padding-top: 30px;
            }

            @include sm {
                flex-direction: column;
                row-gap: 15px;
            }

            &__title {
                color: #050505;
                font-family: var(--mulish);
                font-size: 16px;
                font-weight: 700;
                margin-right: 10px;
                margin-bottom: 0;
            }

            >* {
                width: 60%;

                @include md {
                    width: 100%;
                }
            }
        }
    }

    &-tags {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
    }

    &-tag {
        background-color: #F5F6FA;
        padding: 8px 12px;
        color: rgb(5 5 5 / 80%);
        line-height: 1;
        font-family: var(--mulish);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;

        @include lg {
            padding: 8px 10px;
        }

        &:hover {
            background-color: #509e0f;
            color: var(--white-f);
        }
    }

    &-shares {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 40%;

        @include md {
            width: 100%;
        }

        .rv-inner-socials {
            a {
                border: 1px solid rgba(5, 5, 5, 0.10);
                border-radius: 50%;
                color: rgba(5, 5, 5, 0.40);

                &:hover {
                    color: var(--white-f);
                }
            }
        }
    }

    &-bottom__navs {
        border: 1px solid rgba(5, 5, 5, 0.06);
        margin-top: 80px;
        position: relative;

        @include xl {
            margin-top: 60px;
        }

        @include lg {
            margin-top: 40px;
        }

        @include sm {
            margin-top: 30px;
        }

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: rgba(5, 5, 5, 0.06);
            left: 50%;
            top: 50%;
            translate: -50% -50%;

            @include xxs {
                width: 100%;
                height: 1px;
            }
        }
    }

    &-bottom-nav {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 25px 30px;
        text-transform: uppercase;
        line-height: normal;
        color: var(--black);
        font-family: var(--mulish);
        font-weight: 700;

        @include lg {
            padding: 20px 25px;
        }

        @include sm {
            padding: 15px 20px;
        }

        @include xxs {
            padding: 10px 15px;
        }

        &:hover {
            color: #509e0f;
        }

        i {
            vertical-align: top;
        }

        &__txt {
            color: #838383;

            @include xxs {
                margin-bottom: 3px;
            }
        }

        &.rv-blog-details-bottom-nav-next {
            text-align: right;
        }
    }

    // SIDEBAR
    &-right {
        border: 1px solid rgba(5, 5, 5, 0.14);
        padding: 40px 35px;

        @include xl {
            padding: 30px 25px;
        }

        @include lg {
            padding: 20px;
        }

        &:not(:last-child) {
            margin-bottom: 30px;

            @include xl {
                margin-bottom: 20px;
            }
        }

        &__title {
            color: var(--black);
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;

            @include lg {
                margin-bottom: 15px;
            }
        }
    }

    &-search {
        form {
            display: flex;
            background-color: rgba(245, 246, 250, 1);

            input {
                width: 100%;
                height: 50px;
                padding: 0 15px;
            }

            button {
                height: 50px;
                padding-right: 15px;
            }
        }
    }

    &-categories {
        li:last-child {
            a {
                margin-bottom: 0;
            }
        }
    }

    &-category {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        background-color: rgba(245, 246, 250, 1);
        color: rgb(5, 5, 5, 0.6);
        font-size: 15px;
        font-weight: 500;
        line-height: 1;
        padding: 15px 25px;

        @include xl {
            padding: 15px 20px;
        }

        @include lg {
            padding: 12px 15px;
        }

        &:hover {
            background-color: #509e0f;
            color: var(--white-f);
        }
    }
}

.rv-comments-area {
    margin-top: 94px;

    @include xl {
        margin-top: 64px;
    }

    @include md {
        margin-top: 54px;
    }

    @include sm {
        margin-top: 44px;
    }

    &__title {
        color: var(--black);
        font-size: 26px;
        font-weight: 700;
        line-height: normal;

        @include lg {
            font-size: 24px;
        }

        @include sm {
            font-size: 22px;
        }
    }
}

.rv-comment {
    display: flex;
    margin-top: 20px;
    gap: 20px;
    border-bottom: 1px solid #e8e9e9;
    padding-bottom: 40px;

    @include lg {
        padding-bottom: 30px;
    }

    @include sm {
        padding-bottom: 20px;
    }

    @include xxs {
        flex-direction: column;
        row-gap: 0;
    }

    &-commenter {
        &__img {
            flex-shrink: 0;

            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        &__name {
            color: var(--black);
            font-size: 15px;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 3px;

            @include xxs {
                margin-bottom: 3px;
            }
        }

        &__date {
            color: rgb(5 5 5 / 80%);
            font-family: var(--mulish);
            font-size: 14px;
            font-weight: 600;
            line-height: 185.714%;
        }
    }

    &__txt {
        padding-top: 10px;
    }

    &__descr {
        color: rgb(5 5 5 / 60%);
        font-family: var(--mulish);
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 24px;
    }

    &-bottom-actions {
        display: flex;
        gap: 15px;

        >button {
            border: 1px solid rgba(5, 5, 5, 0.10);
            padding: 0 20px;
            height: 30px;
            line-height: 30px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;

            &:hover {
                background-color: #509e0f;
                border-color: #509e0f;
                color: var(--white-f);
            }
        }
    }

    &-reply {
        margin-top: 40px;
        margin-left: 110px;
        display: flex;
        gap: 20px;

        @include lg {
            margin-top: 30px;
            margin-left: 90px;
        }

        @include sm {
            margin-top: 20px;
            margin-left: 70px;
        }

        @include xs {
            margin-left: 60px;
        }

        @include xxs {
            margin-left: 40px;
            flex-direction: column;
            row-gap: 0;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #e8e9e9;
            padding-bottom: 40px;

            @include lg {
                margin-bottom: 30px;
            }

            @include sm {
                margin-bottom: 20px;
            }
        }
    }

    &-commenter-state {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 400;
    }
}

.rv-comment-form-area {
    margin-top: 94px;

    @include xl {
        margin-top: 64px;
    }

    @include md {
        margin-top: 54px;
    }

    @include sm {
        margin-top: 44px;
    }
}

.rv-comment-form {
    margin-top: 20px;

    input,
    textarea {
        width: 100%;
        border: 1px solid rgba(5, 5, 5, 0.10);
        padding: 15px 25px;
        vertical-align: top;

        @include lg {
            padding: 12px 22px;
        }

        @include sm {
            padding: 10px 15px;
        }

    }

    &__checkbox {
        display: flex;
        align-items: baseline;
        gap: 10px;
        margin-bottom: 4px;

        input,
        label {
            width: max-content;
        }
    }

    .rv-inner-btn {
        width: 100%;
    }
}

.rv-recent-blog {
    display: flex;
    gap: 20px;
    align-items: center;
    font-family: var(--mulish);

    @include lg {
        gap: 15px;
    }

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    img {
        width: 100px;
        height: 100px;
        flex-shrink: 0;

        @include lg {
            width: 80px;
            height: 80px;
        }
    }

    &__date {
        color: rgb(5 5 5 / 80%);
        font-weight: 600;
        font-size: 14px;

        i {
            padding-right: 3px;
        }
    }

    &__title {
        color: var(--black);
        font-size: 17px;
        font-weight: 800;
        line-height: 141.176%;
        margin-bottom: 0;

        @include lg {
            font-size: 16px;
        }

        a:hover {
            color: #509e0f;
        }
    }
}