.rv-checkout {
    padding: 100px 0;
    
    .rv-checkout-title {
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 20px;
    }
    .checkout-form {
        display: flex;
        gap: 25px;
        .rv-billing-details {
            width: 100%;
            .nice-select, input:not([type=checkbox]), textarea {
                width: 100%;
                border: 1px solid #eaeaea;
                height: 50px;
                line-height: 50px;
                padding: 0 20px;
                margin-bottom: 20px;
            }
            select {
                border-radius: 0;
                margin-bottom: 20px;
                height: 50px;
                box-shadow: none;
                border-color: #eaeaea;
                color: #747474;
                padding: 0 20px;
                width: 100%;
            }
            label.create-acc-lebel {
                padding-left: 5px;
            }
            .additional-info {
                margin-top: 50px;
                textarea {
                    margin-bottom: 0;
                    height: 150px;
                    line-height: 1.4;
                    padding: 20px;
                }
            }
        }
        .rv-checkout-sidebar {
            width: 100%;
            .cart-checkout-area {
                width: 100%;
                padding: 20px;
                border-radius: 0;
                margin-bottom: 53px;
                text-align: center;
                border: 1px solid #e4e4e4;
                .checkout-summary {
                    margin-bottom: 30px;
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }
                    }
                    .cart-checkout-total {
                        border-top: 1px solid #222;
                        padding-top: 15px;
                    }
                }
            }
            .cart-checkout-btn {
                border-radius: 6px;
                height: 45px;
                text-transform: capitalize;
                line-height: 42px;
                background-color: #222;
                color: #fff;
                border: 1px solid #222;
                padding: 0 30px;
                font-size: 15px;
                &:hover {
                    background-color: transparent;
                    color: var(--black);
                    border-color: #509e0f;
                }
            }
            input#checkout-payment-option {
                width: 100%;
            }
            .payment-area {
                border: 1px solid #eaeaea;
                padding: 0 15px;
                height: 50px;
                line-height: 50px;
            }
            .checkout-payment-descr {
                padding: 20px 0;
                font-size: 14px;
                color: #777;
                text-transform: capitalize;
                a {
                    color: #509e0f;
                }
            }
            .checkout-form-btn {
                width: 100%;
            }
        }
    }
}