/* ----- 27. Daliy Deals ----- */
.rv-7-daily-deals {
    border: 1px solid rgb(5 5 5 / 10%);
    padding: 30px;
    padding-top: 50px;
    position: relative;

    @include md {
        padding-top: 0;
    }

    @include sm {
        padding: 0;
        border: none;
    }

    &__heading {
        position: absolute;
        bottom: calc(100% - 20px);
        border: 1px solid rgb(5 5 5/ 10%);
        left: 80px;
        padding: 10px 30px;
        background-color: var(--white-f);
        display: flex;
        align-items: center;
        gap: 40px;

        @include xl {
            bottom: calc(100% - 25px);
        }

        @include lg {
            bottom: calc(100% - 30px);
        }

        @include md {
            margin-bottom: 20px;
            flex-direction: column;
            position: static;
            row-gap: 10px;
            margin-top: -20px;
            text-align: center;
        }

        &_right {
            display: flex;
            align-items: center;
            gap: 15px;

            @include xxs {
                flex-direction: column;
                row-gap: 10px;
            }

            h6 {
                margin-bottom: 0;
                color: #050505;
                font-family: var(--mulish);
                font-size: 18px;
                font-weight: 700;
            }
        }
    }


    .rv-7-section__title {
        font-size: 34px;

        @include md {
            font-size: 30px;
        }

        @include sm {
            font-size: 27px;
        }

        @include xxs {
            font-size: 25px;
        }
    }

    &__countdown {
        .syotimer {
            &__body {
                display: flex;
                gap: 6px;
            }

            &-cell {
                display: flex;
                background-color: #D21028;
                border-radius: 30px;
                color: var(--white-f);
                padding: 0 8px;
                height: 34px;
                line-height: 34px;
                min-width: 48px;
                text-align: center;
            }
        }
    }

    .rv-7-slider-nav--small {
        padding: 0 30px;

        @include sm {
            padding: 0;
        }

        @include xs {
            margin-top: 15px;
            gap: 10px;
        }
    }
}

// INDEX-21 DEALS 
.rv-21-bestdeal_section {
    padding-top: 120px;
    z-index: 1;

    @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
        padding-top: 100px;
    }

    @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
        padding-top: 80px;
    }

    @media #{$md,$sm,$mobile,$sm-mobile} {
        padding-top: 60px;
    }

    @media #{$mobile,$sm-mobile} {
        padding-top: 40px;
    }


    .rv-21-bestdeal_section_heading {
        text-align: center;
        margin-bottom: 50px;

        .rv-21-bestdeal_sub_title {
            position: relative;
            font-size: 18px;
            font-weight: 700;
            color: #CE512A;
            z-index: 1;
            font-family: $mulish;

            .rv-21-bestdeal_sh_1 {
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                bottom: -12px;
                z-index: -1;

                @media #{$mobile,$sm-mobile} {
                    bottom: -20px;
                }
            }
        }

        .rv-21-bestdeal_section_title {
            position: relative;
            font-size: 44px;
            font-weight: 700;
            color: #0E1428;
            z-index: 1;

            @media #{$lg} {
                font-size: 40px;
            }

            @media #{$md,$sm,$mobile,$sm-mobile} {
                font-size: 35px;
            }

            @media #{$mobile,$sm-mobile} {
                font-size: 30px;
            }

            @media #{$mobile,$sm-mobile} {
                font-size: 23px;
            }

            .rv-21-bestdeal_sh_1 {
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                bottom: -5px;
                z-index: -1;
            }
        }
    }

    // rv-21-bestdeal_slide
    .rv-21-bestdeal_slide {
        border: 1px solid rgba(2, 2, 8, 0.1);
        padding: 52px 0;
        position: relative;
        z-index: 2;

        @media #{$md,$sm,$mobile,$sm-mobile} {
            margin-bottom: 30px;
            padding: 20px 0;
        }

        // swiper slide button
        .rv-21-bestdeal_slide_button_area {

            .rv-21-bestdeal_slide_button_prev,
            .rv-21-bestdeal_slide_button_next {
                opacity: 0.5;
                transition: all 0.3s ease;
                position: absolute;
                top: 50%;
                content: "";
                z-index: 1;
                transform: translateY(-50%);

                &:hover {
                    opacity: 1;
                }

                @media #{$mobile,$sm-mobile} {
                    top: 46%;
                }

                @media #{$mobile,$sm-mobile} {
                    top: 40%;
                }
            }

            .rv-21-bestdeal_slide_button_prev {
                left: 0;
            }

            .rv-21-bestdeal_slide_button_next {
                right: 0;
            }
        }

        .rv-21-bestdeal_slide_iamge {
            height: 300px;
            width: 400px;
            margin: auto;
            padding-bottom: 50px;
            text-align: center;

            @media #{$md,$sm,$mobile,$sm-mobile} {
                height: 180px;
                padding-bottom: 30px;
            }

            @media #{$sm-mobile} {
                height: 170px;
                padding-bottom: 20px;
            }

            img {
                height: 100%;
                width: auto;
            }
        }

        .rv-21-bestdeal_slide_content {
            text-align: center;
            border-top: 1px solid rgba(2, 2, 8, 0.1);

            .rv-21-bestdeal_slide_review {
                display: flex;
                grid-column-gap: 12px;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                padding-bottom: 10px;
                margin-top: 30px;

                i {
                    color: #FFB21D;
                }

                p {
                    font-family: $mulish;
                    margin: 0;
                    color: #0E1428;
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .rv-21-bestdeal_slide_content_title {
                font-size: 26px;
                font-weight: 700;
                color: #0E1428;
                margin-bottom: 5px;
                font-family: $mulish;

                @media #{$mobile,$sm-mobile} {
                    font-size: 20px;
                }
            }

            .rv-21-bestdeal_slide_product_price {
                display: flex;
                align-items: center;
                column-gap: 15px;
                flex-wrap: wrap;
                justify-content: center;
                padding-bottom: 60px;

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    padding-bottom: 35px;
                }

                @media #{$sm-mobile} {
                    padding-bottom: 20px;
                }

                p {
                    font-size: 20px;
                    font-weight: 500;
                    color: #0E1428;
                    margin: 0;
                    font-family: $mulish;
                }

                span {
                    font-weight: 400;
                    font-size: 14px;
                    font-family: $mulish;
                    color: #0E1428;
                    text-decoration: line-through;
                }
            }

            .rv_simple_timer_7 {
                padding-bottom: 40px;

                @media #{$sm-mobile} {
                    padding-bottom: 25px;
                }

                .count_down_box .count_down {
                    display: inline-block;

                    @media #{$mobile,$sm-mobile} {
                        margin-right: 10px;
                    }

                    .rv-time-style {
                        border: 0.5px solid rgba(14, 20, 40, 0.3);
                        background: linear-gradient(180deg, rgba(14, 20, 40, 0.2) 0%, rgba(14, 20, 40, 0) 100%);
                        backdrop-filter: blur(32.5px);
                        color: #0E1428;
                        font-size: 47px;
                        height: 60px;
                        width: 60px;
                        line-height: 60px;
                        margin-bottom: 12px;
                        font-family: $mulish;

                        @media #{$mobile,$sm-mobile} {
                            font-size: 35px;
                        }

                        @media #{$sm-mobile} {
                            font-size: 25px;
                            height: 45px;
                            width: 35px;
                            line-height: 35px;
                            margin-bottom: 12px;
                            margin: auto;
                            margin-bottom: 10px;
                            text-align: center;
                        }
                    }

                    span {
                        font-family: $mulish;
                        display: block;
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: #0E1428;
                        font-family: $mulish;

                        @media #{$sm-mobile} {
                            font-size: 12px;
                        }
                    }
                }

                .count_down_box .count_down:not(:last-child) {
                    margin-right: 30px;

                    @media #{$mobile,$sm-mobile} {
                        margin-right: 10px;
                    }
                }
            }

            .rv-21-bestdeal_button_area {
                width: 160px;
                height: 80px;
                position: relative;
                cursor: pointer;
                margin: 0 auto;

                &:hover .rv-21-bestdeal_content_btn {
                    letter-spacing: 1px;
                }

                img {
                    height: 100%;
                    width: 100%;
                }

                .rv-21-bestdeal_content_btn {
                    position: absolute;
                    top: 40%;
                    left: 22%;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 700;
                    transition: all 0.4s ease;
                    font-family: $mulish;
                }
            }
        }

    }

    .rv-21-bestdeal_single_product {
        border: 1px solid rgba(2, 2, 8, 0.1);
        position: relative;
        z-index: 1;
        overflow: hidden;
        margin-bottom: 25px;

        &:hover .rv-21-bestdeal_single_product_socials {
            right: 13px;
        }

        .rv-21-bestdeal_single_product_top_actions {
            position: absolute;
            left: 15px;
            top: 15px;

            .rv-21-bestdeal_single_new,
            .rv-21-bestdeal_single_discount {
                background: #CE512A;
                color: #FFFFFF;
                text-align: center;
                padding: 3px 10px;
            }

            .rv-21-bestdeal_single_discount {
                background: #1C74E9;

            }
        }

        .rv-21-bestdeal_single_product_socials {
            position: absolute;
            content: "";
            right: -50px;
            top: 50px;
            transition: all 0.3s linear 0s;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            align-items: center;
            justify-content: center;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                top: 30px;
            }

            a {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 27%);
                color: #0E1428;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.4s ease;
                position: relative;

                &:hover {
                    background: #0E1428;
                    color: #FFFFFF;
                }

                &:hover .quick_view {
                    transform: scale(1);
                }

                .quick_view {
                    position: absolute;
                    left: -88px;
                    background: #0E1428;
                    color: #FFF;
                    font-size: 12px;
                    font-weight: 700;
                    transform: scale(0);
                    transition: 0.3s;
                    z-index: 1;
                    font-family: "Mulish", sans-serif;
                    height: 28px;
                    width: 75px;
                    text-align: center;
                    line-height: 27px;

                    &::after {
                        position: absolute;
                        right: -5px;
                        content: "";
                        height: 20px;
                        width: 20px;
                        background: #0E1428;
                        rotate: 45deg;
                        top: 4px;
                        z-index: -1;
                    }
                }
            }
        }

        .rv-21-bestdeal_single_product_image {
            height: 160px;
            width: 120px;
            margin: 50px auto;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                margin-bottom: 30px;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .rv-21-bestdeal_single_product_txt {
            padding: 15px;
            border-top: 1px solid rgba(14, 20, 40, 0.1);

            .rv-21-bestdeal_single_product_review {
                display: flex;
                grid-column-gap: 12px;
                align-items: center;
                flex-wrap: wrap;

                i {
                    color: #FFB21D;
                }

                p {
                    margin: 0;
                    color: #0E1428;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $mulish;
                }
            }

            .rv_bestdeal_7_single_product_title {
                font-size: 16px;
                font-weight: 700;
                transition: all 0.3s linear 0s;
                margin-bottom: 10px;

                @media #{$sm,$mobile,$sm-mobile} {
                    font-size: 14px;
                }

                a {
                    color: #0E1428;

                    &:hover {
                        color: #CE512A;
                    }
                }

            }

            .rv-21-bestdeal_single_product_price {
                display: flex;
                column-gap: 15px;
                align-items: center;
                flex-wrap: wrap;
                padding-bottom: 15px;

                span {
                    font-family: $mulish;
                    font-family: $mulish;
                    color: #55585B;
                    font-weight: 400;
                    text-decoration: line-through;
                }

                p {
                    font-family: $mulish;
                    font-family: $mulish;
                    margin: 0;
                    color: #0E1428;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
    }
}