/* ----- 14. History ----- */
.rv-2-history {
    padding-bottom: 60px;

    &__img {
        background: url(../../img/rv-2-history-img-bg.png) no-repeat center center;
        background-size: contain;
        text-align: center;

        img {
            @include xxl {
                max-width: 320px;
            }

            @include xl {
                max-width: 100%;
            }
        }
    }

    &__txt {
        padding-right: 65px;

        @include xxl {
            padding-right: 45px;
        }

        @include xl {
            padding-right: 40px;
        }

        @include md {
            padding-right: 0;
        }
    }
}

.rv-2-timeline {
    display: flex;
    gap: 40px;
    margin-bottom: 50px;

    @include xxl {
        gap: 30px;
        margin-bottom: 35px;
    }

    @include xl {
        gap: 25px;
        margin-bottom: 25px;
    }

    @include xxs {
        flex-direction: column;
        gap: 15px;
    }

    &:last-child {
        margin-bottom: 0;

        .rv-2-timeline-time::before {
            height: 100%;
        }
    }

    &-time {
        flex-shrink: 0;
        position: relative;
        width: 115px;

        &::before {
            content: "";
            position: absolute;
            height: calc(100% + 50px);
            width: 2px;
            background-color: #272727;
            right: 0;
            top: 0;

            @include xxs {
                content: none;
            }
        }

        &__years {
            margin-top: 35px;
            padding-right: 15px;
            font-size: 14px;
            font-weight: 500;
            line-height: 71.429%;
            position: relative;

            @include xxs {
                margin-top: 10px;
            }

            &::before {
                position: absolute;
                content: "";
                top: 50%;
                translate: -60% -50%;
                left: 100%;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: linear-gradient(180deg, #C2D600 0%, #F0FF53 100%);
                box-shadow: 0 0 0 6px #272727;
            }
        }
    }

    &-details {
        border: 1px solid rgba(255, 255, 255, 0.06);
        background: linear-gradient(94deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
        padding: 25px 35px 40px;

        @include xxl {
            padding: 25px 35px;
        }

        @include xl {
            padding: 25px 30px;
        }

        @include xs {
            padding: 20px;
        }

        &__title {
            font-size: 20px;
            font-weight: 600;
            border-bottom: 2px solid #DAED1A;
            display: inline-block;
            padding-bottom: 10px;
            margin-bottom: 14px;

            @include xxs {
                font-size: 18px;
            }
        }

        &__descr {
            color: rgb(255 255 255 / 60%);
            font-size: 15px;
            font-weight: 300;
            line-height: 160%;
            margin-bottom: 0;
        }
    }
}