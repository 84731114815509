// Font Family 
$poppins: var(--poppins), sans-serif;
$opensans: var(--open_sans), sans-serif;
$mulish: var(--mulish), sans-serif;

// Responsive Variables
$over:'(min-width: 1800px)';
$xxxl: 'only screen and (min-width: 1600px) and (max-width: 1799px)';
$xxl: 'only screen and (min-width: 1400px) and (max-width: 1599px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
$mobile: 'only screen and (min-width: 480px) and (max-width: 575px)';
$sm-mobile: 'only screen and (min-width: 0px) and (max-width: 479px)';
$xs:'(max-width: 767px)';

// @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
//     font-size: 35px;
// }